<template>
    <div class="alarmRecord-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [],
                    columns: [{
                            label: "报警时间",
                            key: "alarmTime"
                        },
                        {
                            label: "报警位置",
                            key: "alarmLocation",
                            opt: {
                                isUserPopover: true
                            }
                        },
                        {
                            label: "短信推送",
                            key: "isSendStr"
                        },
                        {
                            label: "接警电话",
                            key: "alarmPhone"
                        },
                        {
                            label: "是否接通电话",
                            key: "isCallPhoneStr"
                        },

                        {
                            label: "通话时长",
                            key: "durationStr"
                        },
                    ]
                },
            };
        },
        created() {
            console.log("alarmRecord created!!");

        },
        activated() {
            console.log("alarmRecord activated!!");
        },
        mounted() {},
        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    mac: this.$route.params.mac,
                };
                this.post("device-service/device/l2-alarm-record/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    for (let i = 0; i < res.data.data.length; i++) {
                        let item = res.data.data[i];
                        // 未推送  已推送 
                        item.isSendStr = [
                            "已推送",
                            "未推送",
                        ][item.isSend - 1];
                        if(item.isCallPhone != null && item.isCallPhone !=undefined) {
                            item.isCallPhoneStr = ["响铃但未接通", "接通","拨打失败"][item.isCallPhone]
                        }else {
                            item.isCallPhoneStr = "--"; 
                        } 
                        
                        if (item.scene == 'school') {
                            item.sceneStr = '学校';
                        } else if (item.scene == 'shop') {
                            item.sceneStr = '商店';
                        }
                        item.alarmPhone = item.alarmPhone ? item.alarmPhone : "--";
                        if (!item.duration) {
                            item.durationStr = '--';
                        }
                        if (item.duration) {
                            if (item.duration < 60) {
                                item.durationStr = item.duration + '秒';
                            } else if (item.duration >= 60 && item.duration < 3600) {
                                item.durationStr = parseInt(item.duration / 60) + '分' + (item.duration % 60) +
                                    '秒';
                            } else if (item.duration >= 3600) {
                                let hour, minute, seconds, m, h, s;
                                h = parseInt(item.duration / 3600) + '小时';
                                minute = item.duration - 3600;
                                if (minute >= 60 && minute < 3600) {
                                    m = parseInt(minute / 60) + '分';
                                    s = (minute % 60) + '秒';
                                } else if (minute < 60) {
                                    m = '0分';
                                    s = minute + '秒';
                                }
                                item.durationStr = h + m + s
                            }
                        }
                    }
                    opt.cb(res.data);
                });
            },
        }
    };
</script>
<style lang="scss" scoped>
    .alarmRecord-list  {
        height: calc(100% - 142px);
    }
</style>