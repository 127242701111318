var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-list" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [_c("app-header", { attrs: { title: _vm.title, isShowBack: true } })],
        1
      ),
      _c("ul", { staticClass: "tab-ul" }, [
        _c(
          "li",
          {
            class: { ative: _vm.currentTab == "deviceDetails" },
            on: {
              click: function($event) {
                _vm.toggleTab("deviceDetails")
              }
            }
          },
          [_vm._v("设备详情")]
        ),
        _c(
          "li",
          {
            class: { ative: _vm.currentTab == "alarmRecord" },
            on: {
              click: function($event) {
                _vm.toggleTab("alarmRecord")
              }
            }
          },
          [_vm._v("报警记录")]
        )
      ]),
      _c("keep-alive", [_c(_vm.currentTab, { tag: "deviceDetails" })], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }