<template>
    <div class="deviceDetails">
        <div class="content-box">
            <div class="title">MAC</div><span class="content">{{mac}}</span>
        </div>
        <div class="content-box">
            <div class="title">设备类型</div><span class="content">{{typeStr}}</span>
        </div>
        <div class="content-box">
            <div class="title">固件版本</div><span class="content">{{version}}</span>
        </div>
        <div class="content-box">
            <div class="title">应用场景</div><span class="content">{{sceneStr}}</span>
        </div>
        <div class="content-box">
            <div class="title">报警短信接收电话</div><span class="content">{{receivePhone}}</span>
        </div>
        <div class="content-box">
            <div class="title">报警电话</div><span class="content">{{callPolicePhone}}</span>
        </div>
        <div class="content-box">
            <div class="title">是否开启web端弹框报警</div><span class="content">{{isOpenAlarmStr}}</span>
        </div>
        <div class="content-box">
            <div class="title">安装位置</div><span class="content">{{location}}</span>
        </div>
        <div class="content-box">
            <div class="title">最近一次使用时间</div><span class="content">{{lastAlarm}}</span>
        </div>
        <div class="content-box">
            <div class="title">电量</div><span class="content">{{batteryStr}}</span>
        </div>
        <div class="content-box">
            <div class="title">ICCID</div><span class="content">{{iccid}}</span>
        </div>
        <div class="content-box">
            <div class="title">安装时间</div><span class="content">{{createdTime}}</span>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                mac: '',
                typeStr: '',
                version: '',
                sceneStr: '',
                receivePhone: '',
                callPolicePhone: '',
                isOpenAlarmStr: '',
                location: '',
                lastAlarm: '',
                batteryStr: '',
                iccid: '',
                createdTime: '',
            };
        },
        created() {},
        activated() {
            console.log("deviceDetails activated!!");
            this.getDeviceDetail();
        },
        mounted() {},
        methods: {
            getDeviceDetail() {
                let dto = {
                    pageNum: 1,
                    pageSize: 1,
                    mac: this.$route.params.mac
                };
                this.post("device-service/device/l2-alarm/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    let info = res.data.data[0];
                    this.mac = info.mac;
                    this.typeStr = ['L2D报警器'][info.type - 1];
                    this.version = info.version;
                    if (info.scene == 'school') {
                        this.sceneStr = '学校';
                    } else if (info.scene == 'shop') {
                        this.sceneStr = '商店';
                    }
                    this.receivePhone = info.receivePhone;
                    this.callPolicePhone = info.callPolicePhone;
                    this.isOpenAlarmStr = ['开启', '关闭'][info.isOpenAlarm - 1];
                    this.location = info.location;
                    this.lastAlarm = info.lastAlarm;
                    this.batteryStr = info.battery ? info.battery + '%' : '--';
                    this.iccid = info.iccid;
                    this.createdTime = info.createdTime;
                });

            },
        }
    };
</script>
<style lang="scss" scoped>
    .deviceDetails {
        margin: auto;
        width: 800px;

        .content-box {
            margin: 20px 0;
            font-size: 15px;

            .title,
            .shoppingCodeTitle,
            .containerPicTitle {
                display: inline-block;
                padding-right: 40px;
                text-align: right;
                width: 200px;
                color: #303133;
            }

            .title1 {
                display: inline-block;
                padding-left: 40px;
                padding-right: 10px;
                text-align: right;
                color: #303133;
            }

            .shoppingCodeTitle {
                padding-right: 0;
            }

            .accessCodeTitle {
                width: 80px;
            }

            .content {
                color: #666;
            }

            .locationContent {
                display: inline-block;
                width: 200px;
                color: #666;
                word-break: break-all;
            }

            .editClass {
                display: inline-block;
                color: #30BAC1;
                margin-left: 40px;
                cursor: pointer;
            }

            .shoppingCode {
                display: inline-block;
                width: 252;
                height: 252px;
            }

            .containerPic {
                width: 151px;
                height: 151px;
            }


        }

        .editBox {
            display: inline-block;
            margin: auto;

            .editTitle {
                display: inline-block;
                padding-right: 10px;
                text-align: right;
                width: 101px;
                color: #606266
            }

            .editContent {
                margin: 10px;
            }

            .el-textarea {
                width: 53%;
            }

            .el-input {
                width: 53%;
            }
        }

        .deviceDetails-check-title {
            padding-bottom: 10px;
            font-weight: 700;
        }

        .deviceDetails-check-text {
            padding-bottom: 10px;
            display: flex;

            span {
                padding-right: 46px;
            }
        }

        .deviceDetails-table {
            display: flex;
        }

        .table-box {
            height: 400px;
            overflow-y: auto;
        }
    }

    .deviceDetails {
        .el-dialog {
            // margin-top: 28vh !important;
            // width: 562px;
        }

        .el-dialog__footer {
            text-align: center;
        }

        .el-dialog__header {
            text-align: center;
            font-weight: bold;
        }
    }
</style>