<template>
  <div class="detail-list">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <ul class="tab-ul">
      <li :class="{'ative':currentTab=='deviceDetails'}" @click="toggleTab('deviceDetails')">设备详情</li>
      <li :class="{'ative':currentTab=='alarmRecord'}" @click="toggleTab('alarmRecord')">报警记录</li>
    </ul>
    <keep-alive>
      <deviceDetails :is="currentTab"></deviceDetails>
    </keep-alive>

  </div>
</template>
<script>
  import deviceDetails from './deviceDetails';
  import alarmRecord from './alarmRecord';
  export default {
    components: {
      deviceDetails,
      alarmRecord,
    },
    data() {
      let _this = this;
      return {
        currentTab: 'deviceDetails',
        title: "报警器详情",
        isStockRecord: false,
        isRowCabinet: false,
      };
    },
    activated() {
      this.currentTab = 'deviceDetails';
    },
    mounted() {
      this.currentTab = 'deviceDetails';
    },
    methods: {
      toggleTab: function (tab) {
        this.currentTab = tab; // tab 为当前触发标签页的组件名
      },
    }
  };
</script>
<style lang="scss">
  .detail-list {
    .danger {
      color: #f56c6c;
    }

    .titleSpans {
      padding-bottom: 20px;
    }

    .titleSpan {
      color: #606266;
    }

    .info1 {
      padding: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #606266;

      .txt {
        margin-right: 20px;
      }
    }

    .info {
      padding: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #606266;
      background-color: #e4e7ed;

      .txt {
        margin-right: 20px;
      }
    }

    // tab样式
    .tab-ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      height: 42px;
      box-sizing: border-box;
      margin-bottom: 40px;
      width: 100%;
      border-bottom: 2px solid #e4e7ed;

      li {
        cursor: pointer;
        padding-left: 18px;
        padding-right: 18px;
        height: 42px;
        line-height: 42px;
        float: left;
        text-align: center;
        margin-right: 13px;
        font-size: 15px;
        background-color: #ffffff;
        color: #303133;
        box-sizing: border-box;
        border-bottom: 2px solid #e4e7ed;

        &.ative {
          // color: #ffffff;
          // background: #5e74f1;
          color: #30BAC1;
          border-bottom: 2px solid #30BAC1;
        }
      }

      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .landlord-manage-box .el-tab-pane {
      height: 650px;
    }
  }
</style>