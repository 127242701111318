var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "deviceDetails" }, [
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("MAC")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.mac))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("设备类型")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.typeStr))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("固件版本")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.version))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("应用场景")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.sceneStr))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("报警短信接收电话")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.receivePhone))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("报警电话")]),
      _c("span", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm.callPolicePhone))
      ])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("是否开启web端弹框报警")]),
      _c("span", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm.isOpenAlarmStr))
      ])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("安装位置")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.location))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("最近一次使用时间")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.lastAlarm))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("电量")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.batteryStr))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("ICCID")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.iccid))])
    ]),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("安装时间")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.createdTime))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }